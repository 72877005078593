import React, { useRef } from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import Download from "../../client/compositions/download"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import ProductDetails from "../../client/compositions/product-details"
import StickySnackbar from "../../client/compositions/sticky-snackbar"
import Row from "../../core/components/row"
import { ProductsTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

const inspireWallMountedFeatures = [
  {
    name: "Single Standard",
    image: "/single/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "8, 11, & 22",
      },
      {
        title: "Bracket Depth",
        content: "Single",
      },
      {
        title: "Anchoring Style",
        content: "Wall-mounted",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 24 bottles",
          "4’ column up to 33 bottles",
          "8’ column up to 66 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/single/brushed-stainless-steel/product-01.medium.jpg",
          "/single/brushed-stainless-steel/product-02.medium.jpg",
          "/single/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/single/black-satin/product-01.medium.jpg",
          "/single/black-satin/product-02.medium.jpg",
          "/single/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Double Standard",
    image: "/double/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "8, 11, & 22",
      },
      {
        title: "Bracket Depth",
        content: "Double",
      },
      {
        title: "Anchoring Style",
        content: "Wall-mounted",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 24 bottles",
          "4’ column up to 33 bottles",
          "8’ column up to 66 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/double/brushed-stainless-steel/product-01.medium.jpg",
          "/double/brushed-stainless-steel/product-02.medium.jpg",
          "/double/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/double/black-satin/product-01.medium.jpg",
          "/double/black-satin/product-02.medium.jpg",
          "/double/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Triple Standard",
    image: "/triple/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "8, 11, & 22",
      },
      {
        title: "Bracket Depth",
        content: "Triple",
      },
      {
        title: "Anchoring Style",
        content: "Wall-mounted",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 24 bottles",
          "4’ column up to 33 bottles",
          "8’ column up to 66 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/triple/brushed-stainless-steel/product-01.medium.jpg",
          "/triple/brushed-stainless-steel/product-02.medium.jpg",
          "/triple/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/triple/black-satin/product-01.medium.jpg",
          "/triple/black-satin/product-02.medium.jpg",
          "/triple/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Single Zipper",
    image: "/single-zipper/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "8, 11, & 22",
      },
      {
        title: "Bracket Depth",
        content: "Single",
      },
      {
        title: "Anchoring Style",
        content: "Wall-mounted",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 24 bottles",
          "4’ column up to 33 bottles",
          "8’ column up to 66 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/single-zipper/brushed-stainless-steel/product-01.medium.jpg",
          "/single-zipper/brushed-stainless-steel/product-02.medium.jpg",
          "/single-zipper/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/single-zipper/black-satin/product-01.medium.jpg",
          "/single-zipper/black-satin/product-02.medium.jpg",
          "/single-zipper/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Double Zipper",
    image: "/double-zipper/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "8, 11, & 22",
      },
      {
        title: "Bracket Depth",
        content: "Double",
      },
      {
        title: "Anchoring Style",
        content: "Wall-mounted",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 24 bottles",
          "4’ column up to 33 bottles",
          "8’ column up to 66 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/double-zipper/brushed-stainless-steel/product-01.medium.jpg",
          "/double-zipper/brushed-stainless-steel/product-02.medium.jpg",
          "/double-zipper/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/double-zipper/black-satin/product-01.medium.jpg",
          "/double-zipper/black-satin/product-02.medium.jpg",
          "/double-zipper/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Triple Zipper",
    image: "/triple-zipper/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Post Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Brackets Per Modular Post",
        content: "8, 11, & 22",
      },
      {
        title: "Bracket Depth",
        content: "Triple",
      },
      {
        title: "Anchoring Style",
        content: "Wall-mounted",
      },
      {
        title: "Bottle Capacity Per Post",
        content: [
          "3’ column up to 24 bottles",
          "4’ column up to 33 bottles",
          "8’ column up to 66 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/triple-zipper/brushed-stainless-steel/product-01.medium.jpg",
          "/triple-zipper/brushed-stainless-steel/product-02.medium.jpg",
          "/triple-zipper/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/triple-zipper/black-satin/product-01.medium.jpg",
          "/triple-zipper/black-satin/product-02.medium.jpg",
          "/triple-zipper/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
]

/**
 * Product: Inspire Wall-mounted Page
 * @see https://zpl.io/2j6lyzq
 */
function ProductInspireWallMountedPage() {
  return (
    <article
      id="page-product-inspire-wall-mounted"
      className="page-content product-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            Inspire
            <br />
            Collection
          </>
        }
        content={
          <>
            <p>
              Minimal metal racks securely
              <br /> cradle your wine without
              <br /> visual intrusion.
            </p>
            <Navigation>
              <Anchor to="/products/inspire/wall-mounted/">Wall-mounted</Anchor>
              <Anchor to="/products/inspire/free-standing/">
                Floor-to-ceiling
              </Anchor>
            </Navigation>
          </>
        }
        className="flipped"
      >
        <DecorationFluid image="product-inspire-wall-mounted/banner.jpg" />
      </Banner>

      <StickySnackbar
        id="product-options"
        hiddenBeforeElement="#banner"
        hiddenAfterElement="#slim-fit"
      >
        <Navigation>
          <Anchor to="/products/inspire/wall-mounted/">Wall-mounted</Anchor>
          <Anchor to="/products/inspire/free-standing/">
            Floor-to-ceiling
          </Anchor>
        </Navigation>
      </StickySnackbar>

      <ProductDetails
        data={{
          rootImageDir: "/product-inspire-wall-mounted/features",
          features: inspireWallMountedFeatures,
        }}
      />

      <Row id="display-styles">
        <Block>
          <h4 className="title">
            Display
            <br />
            Styles
          </h4>
          <ul className="items">
            <li className="item">
              <h4 className="title">Standard</h4>
              <DecorationFluid image="product-inspire-wall-mounted/display-01.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Zipper</h4>
              <DecorationFluid image="product-inspire-wall-mounted/display-02.medium.jpg" />
            </li>
          </ul>
        </Block>
      </Row>

      <Row id="slim-fit" className="content-plus-media">
        <Block className="block-content">
          <h2 className="title" style={{ whiteSpace: "nowrap" }}>
            Infinite
            <br /> Versatility
          </h2>
          <div className="content">
            Wall-mounted modular metal racks can be customized to create the
            ultimate wine showcasing experience. Our Inspire collection can be
            customized to meet your desired height, width and depth. Integrate
            with wood to create a transitional and modern look.
            <br />
          </div>
          <Button to="/contact" image="icons/arrow-right.svg">
            Contact
          </Button>
        </Block>
        <Block className="block-media">
          <Overlap
            style={{
              width: "46vw",
              height: "52vw",
            }}
            sizes={[
              { w: "67%", h: "83%" },
              { w: "50%", h: "49%" },
            ]}
          >
            <DecorationFluid image="product-inspire-wall-mounted/versatile-01.medium.jpg" />
            <DecorationFluid image="product-inspire-wall-mounted/versatile-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <Download
        buttons={[
          { label: "Catalogue", url: "http://google.com" },
          {
            label: "CAD files",
            url: "https://www.dropbox.com/sh/kvr609p0y3nqeto/AAA9Qs6chOqTiKP_tmNP_Wj_a?dl=1",
          },
        ]}
      >
        <DecorationFluid image="/product-inspire-wall-mounted/contact.small.jpg" />
      </Download>
      <ProductsTaglineMenu productSlug="product-inspire-wall-mounted" />
    </article>
  )
}

export default ProductInspireWallMountedPage
